<template>
  <div class="big mydialog">
    <div class="container">
      <div class="searchbox">
        <el-button type="primary"
                   style="width:124px;"
                   icon="el-icon-plus"
                   @click="AddRole"
                   v-if="module_id_array.includes('37')||module_id_array.includes('999')">添加角色</el-button>
      </div>
      <div style="border: solid 1px #c9cbd6;">
        <el-table ref="multipleTable"
                  :data="tableData"
                  tooltip-effect="dark"
                  style="width: 100%;min-width:1000px;"
                  :header-cell-style="{background:'#F5F5F5'}">
          <el-table-column prop="group_name"
                           label="角色"></el-table-column>
          <el-table-column prop="desc"
                           label="角色描述"></el-table-column>

          <el-table-column prop="admin_count"
                           label="关联用户数"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="checkRole(scope.row)"
                         type="text"
                         v-if="scope.row.group_id==1">查看权限</el-button>

              <el-button @click="editRole(scope.row)"
                         type="text"
                         v-if="scope.row.group_id!=1&&(module_id_array.includes('38')||module_id_array.includes('999'))">
                编辑</el-button>

              <el-button @click="delRole(scope.row.group_id)"
                         type="text"
                         v-if="scope.row.group_id!=1&&!scope.row.user_arr_name&&(module_id_array.includes('35')||module_id_array.includes('999'))">
                删除</el-button>

              <el-button @click="setRole(scope.row)"
                         type="text"
                         v-if="scope.row.group_id!=1&&(module_id_array.includes('39')||module_id_array.includes('999'))">
                权限设置</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-sizes="[10, 20, 50, 100]"
                       :page-size="10"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="totalNum"
                       style="margin:30px 0 40px 0"></el-pagination>
      </div>
    </div>
    <!-- 添加角色弹窗 -->
    <el-dialog :title="title"
               :visible.sync="addrole"
               width="580px"
               :close-on-click-modal="false">
      <div class="myform">
        <el-form :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="角色名"
                        prop="group_name">
            <el-input v-model="ruleForm.group_name"
                      placeholder="请输入10位以内汉字或字符"
                      maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="角色描述"
                        prop="desc">
            <el-input type="textarea"
                      v-model="ruleForm.desc"
                      placeholder="20字以内"
                      maxlength="20"
                      show-word-limit></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="submit('ruleForm')">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage: 1,
      pagesize: 10,
      totalNum: 0,
      adminList: [],
      tableData: [],
      addrole: false,
      title: "添加角色",
      ruleForm: {
        group_name: "",
        desc: "",
      },
      rules: {
        group_name: [
          {
            required: true,
            message: "请输入10位以内汉字或字符",
            trigger: "blur",
          },
          { max: 10, message: "请输入10位以内汉字或字符", trigger: "blur" },
        ],
      },
      adminnum: "",
      module_id_array: window.utils.storage.getter("module_id_array") || [],
    };
  },

  methods: {
    //获取角色列表
    getList() {
      let page = this.currentPage;
      let pageNumber = this.pagesize;
      this.axios
        .post("/store/Admin/getAdminGroupList", { page, pageNumber })
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data.list;
            this.totalNum = res.data.total;
          }
        });
    },
    //每页数据的条数
    handleSizeChange(size) {
      this.pagesize = size;
      this.getList();
    },
    //当前第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    //添加角色按钮
    AddRole() {
      this.title = "添加角色";
      this.ruleForm = {
        group_name: "",
        desc: "",
      };
      this.addrole = true;
    },
    //添加/编辑 角色
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let group_name = this.ruleForm.group_name;
          let desc = this.ruleForm.desc;
          if (this.title == "添加角色") {
            this.axios
              .post("/store/Admin/addAdminGroup", { group_name, desc })
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("添加成功");
                  this.addrole = false;
                  this.getList();
                }
              });
          } else {
            let group_id = this.ruleForm.group_id;
            this.axios
              .post("/store/Admin/editAdminGroup", {
                group_name,
                desc,
                group_id,
              })
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("修改成功");
                  this.addrole = false;
                  this.getList();
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改角色按钮
    editRole(row) {
      this.title = "编辑角色";
      this.addrole = true;
      this.ruleForm = {
        group_name: row.group_name,
        desc: row.desc,
        group_id: row.group_id,
      };
    },
    //删除角色
    delRole(group_id) {
      this.$confirm("您确定删除该角色？", "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.axios
            .post("/store/Admin/delAdminGroup", { group_id })
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功");
                if (!(this.tableData.length - 1) % this.pagesize) {
                  this.currentPage =
                    this.currentPage == 1 ? 1 : this.currentPage - 1;
                }
                this.getList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => { });
    },
    //权限设置
    setRole(row) {
      this.$router.push({
        path: "/system/setrole",
        query: {
          group_id: row.group_id,
          group_name: row.group_name,
          module_id_array: row.module_id_array,
        },
      });
    },
    //查看系统管理员的角色权限
    checkRole() {
      this.$router.push("checkrole");
    },
  },

  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.myform {
  width: 500px;
  padding-top: 20px;
}
.rolenum {
  color: #669961;
  cursor: pointer;
}
.adminbox {
  text-align: left;
  min-height: 400px;
}
.admintitle {
  display: flex;
  background: #f5f5f5;
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  color: #262626;
  div {
    flex: 1;
    text-align: center;
  }
}
.adminitem {
  display: flex;
  padding: 0 20px;
  line-height: 48px;
  box-sizing: border-box;
  color: #262626;
  border-bottom: 1px solid #e8e9f0;
  div {
    flex: 1;
    text-align: center;
  }
}
</style>
<style lang="scss">
// .mydialog .el-dialog__body {
//   padding: 0px !important;
// }
</style>